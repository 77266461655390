import React from 'react';
import { graphql } from 'gatsby';
import { useBibliographyJsonData } from 'hooks';
import Layout from 'components/layout';
import SeoComponent from 'components/seo';
import { Breadcrumb } from 'components/ui';

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== 'undefined';

const BibliographyDetail = ({ location, data }) => {
  const bibliographyData = data.bibliography;

  // Fetching Tags for the bibliography JSON object
  const { allBibliography: bibliographyPayload } = useBibliographyJsonData();
  const newArr = bibliographyPayload.nodes[0].bibliographyTagFilters.filter(
    (filterTag) => filterTag.BibliograhpyID === bibliographyData.ID
  );
  const tagList = bibliographyPayload.nodes[0].bibliographyTag;
  const tagData = tagList.filter((tagDataObj) => {
    return newArr.find((filteredObj) => {
      return filteredObj.BibliographyTagID === tagDataObj.ID;
    });
  });

  let tagNames = [];
  if (tagData.length > 0) {
    tagNames = tagData.map(tag => tag.Name);
  }

  const tagsField = tagNames.join('; ');
  let thisLocationPathname = '';
  if (isBrowser) {
    thisLocationPathname = window.location.pathname;
  }

  return (
    <Layout location={location}>
      <SeoComponent title={bibliographyData.Title} />

      <div className="margin-bottom-3">
        <div className="grid-container">
          <div className="grid-row">
            <Breadcrumb
              pathname={thisLocationPathname}
              customCurrentPage={bibliographyData.Title}
            />
          </div>
          <div className="grid-row">
            <h1
              className="usa-hero__heading"
              aria-label="Hero: Interactive Bibliography Search"
            >
              {bibliographyData.Title}
            </h1>
          </div>
          <div className="grid-row">
            <h2 className="usa-hero__subheading margin-bottom-0">Authors</h2>
          </div>
          <div className="grid-row">
            <p className="margin-top-0">{bibliographyData.Author}</p>
          </div>
          <div className="grid-row">
            <h2 className="usa-hero__subheading margin-bottom-0">
              Publisher Information
            </h2>
          </div>
          <div className="grid-row">
            {/* Publication Year */}
            {bibliographyData.PublicationYear && (
              <>
                <div className="desktop:grid-col-2 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">Publication Year:</p>
                </div>
                <div className="desktop:grid-col-9 desktop:padding-right-7 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">{bibliographyData.PublicationYear}</p>
                </div>
              </>
            )}
            {/* Publication Title */}
            {bibliographyData.PublicationTitle && (
              <>
                <div className="desktop:grid-col-2 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">Publication Title:</p>
                </div>
                <div className="desktop:grid-col-9 desktop:padding-right-7 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">{bibliographyData.PublicationTitle}</p>
                </div>
              </>
            )}
            {/* ISBN */}
            {bibliographyData.ISBN && (
              <>
                <div className="desktop:grid-col-2 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">ISBN:</p>
                </div>
                <div className="desktop:grid-col-9 desktop:padding-right-7 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">{bibliographyData.ISBN}</p>
                </div>
              </>
            )}
            {/* ISSN */}
            {bibliographyData.ISSN && (
              <>
                <div className="desktop:grid-col-2 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">ISSN:</p>
                </div>
                <div className="desktop:grid-col-9 desktop:padding-right-7 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">{bibliographyData.ISSN}</p>
                </div>
              </>
            )}
            {/* Pages */}
            {bibliographyData.Pages && (
              <>
                <div className="desktop:grid-col-2 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">Pages:</p>
                </div>
                <div className="desktop:grid-col-9 desktop:padding-right-7 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">{bibliographyData.Pages}</p>
                </div>
              </>
            )}
            {/* Issue */}
            {bibliographyData.Issue && (
              <>
                <div className="desktop:grid-col-2 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">Issue:</p>
                </div>
                <div className="desktop:grid-col-9 desktop:padding-right-7 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">{bibliographyData.Issue}</p>
                </div>
              </>
            )}
            {/* Volume */}
            {bibliographyData.Volume && (
              <>
                <div className="desktop:grid-col-2 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">Volume:</p>
                </div>
                <div className="desktop:grid-col-9 desktop:padding-right-7 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">{bibliographyData.Volume}</p>
                </div>
              </>
            )}
            {/* JournalAbbreviation */}
            {bibliographyData.JournalAbbreviation && (
              <>
                <div className="desktop:grid-col-2 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">JournalAbbreviation:</p>
                </div>
                <div className="desktop:grid-col-9 desktop:padding-right-7 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">{bibliographyData.JournalAbbreviation}</p>
                </div>
              </>
            )}
            {/* DOI */}
            {bibliographyData.DOI && (
              <>
                <div className="desktop:grid-col-2 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">DOI:</p>
                </div>
                <div className="desktop:grid-col-9 desktop:padding-right-7 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">{bibliographyData.DOI}</p>
                </div>
              </>
            )}
            {/* Publisher */}
            {bibliographyData.Publisher && (
              <>
                <div className="desktop:grid-col-2 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">Publisher:</p>
                </div>
                <div className="desktop:grid-col-9 desktop:padding-right-7 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">{bibliographyData.Publisher}</p>
                </div>
              </>
            )}
            {/* URL */}
            {bibliographyData.Url && (
              <>
                <div className="desktop:grid-col-2 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">Url:</p>
                </div>
                <div className="desktop:grid-col-9 desktop:padding-right-7 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0"><a target='_blank' rel='noreferrer' href={bibliographyData.Url}>{bibliographyData.Url}</a></p>
                </div>
              </>
            )}
            {/* Place Information  */}
            {bibliographyData.Place && (
              <>
                <div className="desktop:grid-col-2 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">Place:</p>
                </div>
                <div className="desktop:grid-col-9 desktop:padding-right-7 tablet:grid-col-12 margin-bottom-0">
                  <p className="margin-0">{bibliographyData.Place}</p>
                </div>
              </>
            )}
          </div>
          <div className="grid-row">
            <h2 className="usa-hero__subheading margin-bottom-0">Tags</h2>
          </div>
          <div className="grid-row">
            <p className="margin-top-0">
              {tagsField}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($slug: String) {
    bibliography(TitleId: { eq: $slug }) {
      ID
      Author
      Title
      PublicationYear
      BibliographyTypeID
      PublicationTitle
      ISBN
      ISSN
      DOI
      Url
      TitleId
      Pages
      Issue
      Volume
      JournalAbbreviation
      Publisher
      Place
      LinkAttachments
    }
  }
`;

export default BibliographyDetail;
